.Login {
    position: relative;
}

.Login::before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100vh;
    background-image: url('../../assets/images/Minerva_vertical.png'); 
    background-size: 800px;
    background-position: 100% 100%;
    background-repeat: no-repeat;
    filter: blur(10px); 
    z-index: -1; 
}
